<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 194px"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="position">
      <l-icon
        :iconSize="[60, 60]"
        :iconAnchor="[15, 50]"
        :popupAnchor="[4, -42]"
      >
        <icons-svg :id="iconId" :color="iconColor"></icons-svg>
      </l-icon>
    </l-marker>
  </l-map>
</template>
<script>
//import L from 'leaflet';
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import IconsSvg from "@/components/Icons/Icons";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "device-map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    IconsSvg,
  },
  props: { device: { required: true, type: Object } },
  data: () => ({
    zoom: 13.5,
    position: latLng(-33.4335796, -70.5962481),
    center: latLng(-33.4335796, -70.5962481),
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentZoom: 13.5,
    currentCenter: latLng(-33.4335796, -70.5962481),
    mapOptions: {
      zoomSnap: 0.5,
    },
    iconId: 1,
    iconColor: "#23C577",
  }),
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    getLastPosition() {
      this.$store
        .dispatch("device/getLastPosition", this.device.id)
        .then((position) => {
          if (position !== null)
            this.position = latLng(position.latitude, position.longitude);
        });
    },
    setComponent() {
      this.position = latLng(
        this.device.position.latitude,
        this.device.position.longitude
      );
      this.center = latLng(
        this.device.position.latitude,
        this.device.position.longitude
      );
      this.iconId = this.device.icon_id;
      this.iconColor = this.device.icon_color;
      this.interval = setInterval(() => this.getLastPosition(), 5000);
    },
  },
  mounted() {
    this.setComponent();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
