<template>
  <md-card>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <ul>
            <li>
              {{ $t("device.deviceDetail.identifier") }}:
              {{ device.identifier }}
            </li>
            <li>{{ $t("device.deviceDetail.alias") }}: {{ device.alias }}</li>
            <li>
              {{ $t("device.deviceDetail.radiusOfCoverage") }}:
              {{ device.radius_of_coverage }} m
            </li>
            <li>
              {{ $t("device.deviceDetail.gps") }}:
              {{
                device.has_gps
                  ? $t("device.deviceDetail.hasGps")
                  : $t("device.deviceDetail.hasNotGps")
              }}
            </li>
            <li v-if="device.worker_id">
              {{ $t("device.deviceDetail.worker") }}: {{ device.worker_name }}
              {{ device.worker_last_name }}
            </li>
            <li>
              {{ $t("device.deviceDetail.createdAt") }}: {{ device.timestamp }}
            </li>
          </ul>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "device-info",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
    device: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  data() {
    return {
      direction: "bottom",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: "slide-y-reverse-transition",
    };
  },
};
</script>