<template>
  <div class="row">
    <div class="col-12" :class="{ 'col-md-12': !showMap, 'col-md-6': showMap }">
      <device-info :device="device" />
    </div>
    <div
      class="col-12"
      :class="{ 'col-md-12': !showMap, 'col-md-6': showMap }"
      v-if="showMap"
    >
      <md-card>
        <md-card-content>
          <device-map :device="device" />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DeviceInfo from "../../components/Devices/DeviceInfo";
import DeviceMap from "../../components/Maps/DeviceMap";
export default {
  components: {
    DeviceInfo,
    DeviceMap,
  },
  data: () => ({
    device: {},
    showMap: false,
  }),
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
          if (this.device.position !== null) {
            this.showMap = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDevice();
  },
};
</script>